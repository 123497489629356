import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  Container,
  createStyles,
  CssBaseline,
  StyleRules,
  Theme,
  Typography,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import i18n from 'i18next';

import FAvatar from '../components/Avatar';
import Footer from '../components/Footer';
import Notification from '../components/Notification';
import { AppState } from '../reducers';
import { getNotification } from '../reducers/notificationReducer';
import { FUserTypeEnum } from '../reducers/userReducer';

const styles = (theme: Theme): StyleRules =>
  createStyles({
    root: {
      margin: 'auto',
      paddingTop: '7%',
      paddingBottom: '5%',
    },
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    slogan: {
      marginTop: theme.spacing(1),
    },
  });

const LoginLayout = ({ classes, children }: Props): React.ReactElement => {
  const user = useSelector((appState: AppState) => appState.user);
  const { t } = useTranslation();
  i18n.loadNamespaces('LoginLayout');
  const { isVisible, severity, message } = useSelector(getNotification);
  return (
    <>
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
        <link rel="stylesheet" href="https://fonts.loli.net/icon?family=Material+Icons" />
      </Helmet>
      <div className={classes.root}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <div className={classes.paper}>
            <FAvatar />
            <Typography component="h1" variant="h5" className={classes.slogan}>
              {user.userType !== FUserTypeEnum.CONSULTANT
                ? t('login_layout:title')
                : '傅通自由职业'}
            </Typography>
          </div>
        </Container>
        {children}
      </div>
      <Footer></Footer>
      {isVisible && <Notification isVisible={isVisible} severity={severity} message={message} />}
    </>
  );
};

export interface Props extends WithStyles<typeof styles> {
  children: React.ReactElement | React.ReactElement[];
}

export default withStyles(styles)(LoginLayout);
