import React, { ReactElement } from 'react';
import { createStyles, StyleRules, Theme, WithStyles, withStyles } from '@material-ui/core/styles';

import fintegrity from '../images/fintegrity-logo-horizontal.png';

const styles = (theme: Theme): StyleRules =>
  createStyles({
    root: {
      display: 'flex',
      margin: theme.spacing(1),
    },
    img: {
      margin: 'auto',
      width: '66%',
    },
  });

const FAvatar = ({ classes }: WithStyles<typeof styles>): ReactElement => (
  <div className={classes.root}>
    <img src={fintegrity} className={classes.img} />
  </div>
);

export default withStyles(styles)(FAvatar);
