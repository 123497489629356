import React from 'react';
import { createStyles, Grid, StyleRules, WithStyles, withStyles } from '@material-ui/core';
import { navigate } from 'gatsby';

import SubmitButton from '../components/Button/SubmitButton';
import Layout from '../layout/LoginLayout';

const styles = (): StyleRules =>
  createStyles({
    container: {
      height: 480,
      textAlign: 'center',
    },
    item: {
      margin: 'auto',
      fontSize: '2em',
    },
  });

const NotFoundPage = ({ classes }: Props): React.ReactElement => (
  <Layout>
    <Grid
      container
      direction="column"
      alignContent="center"
      alignItems="center"
      className={classes.container}
    >
      <Grid item className={classes.item}>
        页面不存在
      </Grid>
      <Grid item className={classes.item}>
        <SubmitButton
          onClick={() => {
            navigate('/');
          }}
        >
          返回首页
        </SubmitButton>
      </Grid>
    </Grid>
  </Layout>
);

export type Props = WithStyles<typeof styles>;

export default withStyles(styles)(NotFoundPage);
